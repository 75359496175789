import React from 'react'
import "./Download.css"
import Fade from 'react-reveal/Fade';

import Ct11 from "./../../Asset/pdf/Crompton.pdf"
import Ct12 from "./../../Asset/pdf/k1.pdf"
import Ct13 from "./../../Asset/pdf/k2.pdf"
import Ct14 from "./../../Asset/pdf/k3.pdf"
import Ct15 from "./../../Asset/pdf/k4.pdf"
import Ct16 from "./../../Asset/pdf/k5.pdf"
import Ct17 from "./../../Asset/pdf/k6.pdf"
import Ct18 from "./../../Asset/pdf/k7.pdf"
import Ct19 from "./../../Asset/pdf/k8.pdf"
import Ct21 from "./../../Asset/pdf/k9.pdf"
import Ct22 from "./../../Asset/pdf/k10.pdf"
import Ct23 from "./../../Asset/pdf/rr3.pdf"
import Ct24 from "./../../Asset/pdf/rrfans.pdf"
import Ct25 from "./../../Asset/pdf/rrlight.pdf"
import Ct28 from "./../../Asset/pdf/stan1.pdf"

import Ct26 from "./../../Asset/pdf/stan2.pdf"
import Ct27 from "./../../Asset/pdf/surya1d.pdf"


import C6 from './../../Asset/c6.jpg'; 
import C3 from './../../Asset/c3.png'; 
import C34 from './../../Asset/c34.png'; 
import C35 from './../../Asset/C35.png'; 
import C36 from './../../Asset/C36.png'; 
import C37 from './../../Asset/C37.png'; 
import C38 from './../../Asset/C38.jpg'; 


const Download = () => {
  return (
    <div className='downl'>
<h4 className='GLLd'> DOWNLOAD BROUCHERS</h4>
<br></br>
<Fade left>   
<div className='dro'>
<a href={Ct11} className="dlin"><div> <img src={C38} className='dig'></img> <h5 className='dh5'>Crompton - 01</h5> </div></a>
<a href={Ct12} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 01</h5> </div></a>
<a href={Ct13} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 02</h5> </div></a>
<a href={Ct14} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 03</h5> </div></a>
</div>
</Fade>
<Fade right>   
<div className='dro'>
<a href={Ct15} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 04</h5> </div></a>
<a href={Ct16} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 05</h5> </div></a>
<a href={Ct17} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 06</h5> </div></a>
<a href={Ct18} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 07</h5> </div></a>
</div>
</Fade>
<Fade left>   
<div className='dro'>
<a href={Ct19} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 08</h5> </div></a>
<a href={Ct21} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 09</h5> </div></a>
<a href={Ct22} className="dlin"><div> <img src={C6} className='dig'></img> <h5 className='dh5'>Kraus & Naimer - 010</h5> </div></a>
<a href={Ct23} className="dlin"><div> <img src={C34} className='dig'></img> <h5 className='dh5'>RR ELECTRIC - 01</h5> </div></a>
</div>
</Fade>
<Fade right>   
<div className='dro'>
<a href={Ct24} className="dlin"><div> <img src={C35} className='dig'></img> <h5 className='dh5'>RR FANS - 01</h5> </div></a>
<a href={Ct25} className="dlin"><div> <img src={C36} className='dig'></img> <h5 className='dh5'>RR LIGHTS - 01</h5> </div></a>
<a href={Ct28} className="dlin"><div> <img src={C3} className='dig'></img> <h5 className='dh5'>STANDARD - 01</h5> </div></a>
<a href={Ct26} className="dlin"><div> <img src={C3} className='dig'></img> <h5 className='dh5'>STANDARD - 02</h5> </div></a>
<a href={Ct27} className="dlin"><div> <img src={C37} className='dig'></img> <h5 className='dh5'>SURYA LIGHTSTYLES - 01</h5> </div></a>
  </div> </Fade> </div>
  )
}

export default Download