import React, { Component } from "react";
import  './Cli.css';
import Slider from "react-slick";
import Patron from "./Patron";
import Fade from 'react-reveal/Fade';

import img1 from './../../Asset/c1.jpeg';
import img2 from './../../Asset/c2.jpg';
import img3 from './../../Asset/c3.png';
import img4 from './../../Asset/c4.jpg';
import img5 from './../../Asset/c5.png';
import img6 from './../../Asset/c6.jpg';
import img7 from './../../Asset/c7.png';
import img8 from './../../Asset/c8.jpg';
import img9 from './../../Asset/c9.png';
import img10 from './../../Asset/c10.jpg';
import img11 from './../../Asset/c11.png';
import img12 from './../../Asset/c12.jpg';
import img13 from './../../Asset/c13.png';
import img14 from './../../Asset/c14.png';
import img15 from './../../Asset/c15.png';
import img16 from './../../Asset/c16.png';
import img17 from './../../Asset/c17.png';
import img18 from './../../Asset/c18.png';
import img19 from './../../Asset/c19.png';
import img20 from './../../Asset/c20.png';
import img21 from './../../Asset/c21.png';
import img22 from './../../Asset/c22.png';
import img23 from './../../Asset/c23.png';
import img24 from './../../Asset/c24.png';
import img25 from './../../Asset/c25.png';
import img26 from './../../Asset/c26.png';
import img27 from './../../Asset/c27.png';
import img28 from './../../Asset/c28.png';
import img29 from './../../Asset/c29.png';
import img30 from './../../Asset/c30.png';
import img31 from './../../Asset/c31.png';
import img32 from './../../Asset/c32.png';
import img33 from './../../Asset/C33.png';



export default class Cli extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 2000,
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 400,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                  }
                }]
        };
        return (
            <div className="clihold">
                <h1 className="clihead">OUR CLIENTS </h1>
              
                <Slider {...settings}>
                    <div>
                        <Patron img={img1}></Patron>
                    </div>
                    <div>
                        <Patron img={img2} ></Patron>
                    </div>
                    <div>
                        <Patron img={img3} ></Patron>
                    </div>
                    <div>
                        <Patron img={img4} ></Patron>
                    </div>
                    <div>
                        <Patron img={img5} ></Patron>
                    </div>
                    <div>
                        <Patron img={img6} ></Patron>
                    </div>
                    <div>
                        <Patron img={img7} ></Patron>
                    </div>
                    <div>
                        <Patron img={img8} ></Patron>
                    </div>
                    <div>
                        <Patron img={img9} ></Patron>
                    </div>
                    <div>
                        <Patron img={img10} ></Patron>
                    </div>
                    <div>
                        <Patron img={img11} ></Patron>
                    </div>
                    <div>
                        <Patron img={img12} ></Patron>
                    </div>
                    <div>
                        <Patron img={img13} ></Patron>
                    </div>
                    <div>
                        <Patron img={img14} ></Patron>
                    </div>
                    <div>
                        <Patron img={img15} ></Patron>
                    </div>
                    <div>
                        <Patron img={img16} ></Patron>
                    </div>
                    <div>
                        <Patron img={img17} ></Patron>
                    </div>
                    <div>
                        <Patron img={img18} ></Patron>
                    </div>
                    <div>
                        <Patron img={img19} ></Patron>
                    </div>
                    <div>
                        <Patron img={img20} ></Patron>
                    </div>
                    <div>
                        <Patron img={img21} ></Patron>
                    </div>
                    <div>
                        <Patron img={img22} ></Patron>
                    </div>
                    <div>
                        <Patron img={img23} ></Patron>
                    </div>
                    <div>
                        <Patron img={img24} ></Patron>
                    </div>

                       <div>
                        <Patron img={img25} ></Patron>
                    </div>
                    <div>
                        <Patron img={img26} ></Patron>
                    </div>
                    <div>
                        <Patron img={img27} ></Patron>
                    </div>
                    <div>
                        <Patron img={img28} ></Patron>
                    </div>
                    <div>
                        <Patron img={img29} ></Patron>
                    </div>
                    <div>
                        <Patron img={img30} ></Patron>
                    </div>
                    <div>
                        <Patron img={img31} ></Patron>
                    </div>
                    <div>
                        <Patron img={img32} ></Patron>
                    </div>
                    <div>
                        <Patron img={img33} ></Patron>
                    </div>
                 

                </Slider>
            </div>
        );
    }
}
