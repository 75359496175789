import { BrowserRouter, Routes, Route} from 'react-router-dom';
import About from './Pages/About';
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Product from "./Pages/Products";
import Client from "./Pages/Clients";
import Activities from "./Pages/Activities";
import Download from "./Pages/Download";
import RootLayout from './Pages/RootLayout';
import P1 from "./Components/Product/P1";
import P2 from "./Components/Product/P2";
import P3 from "./Components/Product/P3";
import P4 from "./Components/Product/P4";
import P5 from "./Components/Product/P5";
import P6 from "./Components/Product/P6";
import P7 from "./Components/Product/P7";
import P8 from "./Components/Product/P8";
import P9 from "./Components/Product/P9";
import P10 from "./Components/Product/P10";
import P11 from "./Components/Product/P11";
import P12 from "./Components/Product/P12";
import P13 from "./Components/Product/P13";
import P14 from "./Components/Product/P14";

import Blog from "./Components/blog/Blog";
import Blogs from "./Components/blog/Blogs";

function App() {
  return (
    <div className="App">
     
     <BrowserRouter>

     <Routes>


<Route path="/" element={<Home />} />
<Route  path="/About" element={<About/>} /> 
<Route  path="/Contact" element={<Contact/>} /> 
<Route  path="/Gallry" element={<Gallery/>} /> 
<Route  path="/Activities" element={<Activities/>} /> 
<Route  path="/Products" element={<Product/>} /> 
<Route  path="/Clientele" element={<Client/>} /> 
<Route  path="/Download" element={<Download/>} /> 
<Route  path="/PowerPanel" element={<P1/>} /> 
<Route  path="/ControlPanel" element={<P2/>} /> 
<Route  path="/GeneratorPanels" element={<P3 />} /> 
<Route  path="/AutomationPanels" element={<P4/>} /> 
<Route  path="/LoadBank" element={<P5/>} /> 
<Route  path="/PLCPanel" element={<P6/>} /> 
<Route  path="/OperatorPanels" element={<P7/>} /> 
<Route  path="/BatteryCharger" element={<P8/>} /> 
<Route  path="/UPS" element={<P9/>} />
<Route  path="/SpecialPurposeMacines" element={<P10/>} /> 
<Route  path="/HydroTestingMacine" element={<P11/>} />
<Route  path="/BushPressingMachine" element={<P12/>} /> 
<Route  path="/NutRunnerMachine" element={<P13/>} /> 
<Route  path="/AssemblyFIxtures" element={<P14/>} /> 

<Route  path="/Blog" element={<Blog/>} /> 
<Route  path="/Blogs" element={<Blogs/>} /> 
     </Routes>  
     
     </BrowserRouter>

    </div>
  );
}

export default App;
