import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import "./P2.css"
import A from "./../../Asset/p2.webp";
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';

const P2 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P2">

<div className='P1c1'>
<h3 className='p1h p1hy'>CONTROL PANEL :</h3>
<br />
<Fade left>
<p className='p1hp'>
&emsp;&emsp;Prominent & leading Exporter from Pune, we supply finest quality Electricity Control Panel Boards including Control Panel Boards, Electrical Control Panels, Process Control Panels as per client requirements.
</p>
<br />
<h5 className='p1h'>Control Panel Boards</h5>

<p className='p1hp'>&emsp;&emsp;Control Panel BoardsWe wisely take this opportunity to announce our name as one of the foremost manufacturers and exporters of Control Panel Boards. As per industry laid quality standards and norms, we are manufacturing wide array of panel boards using high grade components & materials.</p>

<br />
<h5 className='p1h'>Electrical Control Panels</h5>

<p className='p1hp'>&emsp;&emsp;Electrical Control PanelsBeing one of the renowned names in the electrical industry, we are engaged in manufacturing wide range of Electrical Control Panels for our clients. </p>


<br />
<h5 className='p1h'>Process Control Panels</h5>

<p className='p1hp'>&emsp;&emsp;Process Control Panels Persistently marching in today scutthroat market, our organization is recognized as one of the well established manufacturers and exporters of Process Control Panels. Our quality auditors are conducting various quality tests using equipments like frequency indicator. </p>

<br />
<h5 className='p1h'>General Control Panel</h5>

<p className='p1hp'>&emsp;&emsp;General Control Panel We take immense pride to declare ourselves as one of the trustworthy manufacturers, suppliers and exporters of General Control Panel. Due to sophisticated machines like notching machines and drilling machines. </p>

</Fade>
</div>

<div className='P1c2'>
<Fade right>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
    </Fade>
</div>

</div>

    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P2