import React from 'react';
import { Outlet } from "react-router-dom";

import Nav from './../Components/Nav/Nav';
import Footer from './../Components/Footer/Foot';



function RootLayout() {
  return (
    <div>
      
      <Nav></Nav>
      <Outlet></Outlet>
      <Footer></Footer>
     
      
    </div>
  )
}

export default RootLayout
