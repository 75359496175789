import React from 'react'
import { useEffect } from 'react';
import Nav from './../Components/Nav/Nav';
import Foot from '../Components/Footer/Foot';
import Down from './../Components/Download/Download';
import Whatsapp from './../Components/Whatsapp/Wpp';
import Cb from './../Components/CB/Cb'
const Download = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
      <br/> <br/> <br/> <br/><br />
<Down />
<Whatsapp></Whatsapp>
<Cb />
<Foot />

    </div>
  )
}

export default Download