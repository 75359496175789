import React from 'react'
import "./Client.css"
import Fade from 'react-reveal/Fade';
import C1 from './../../Asset/c1.jpeg';
import C2 from './../../Asset/c2.jpg';
import C3 from './../../Asset/c3.png';
import C4 from './../../Asset/c4.jpg';
import C5 from './../../Asset/c5.png';
import C6 from './../../Asset/c6.jpg';
import C7 from './../../Asset/c7.png';
import C8 from './../../Asset/c8.jpg';
import C9 from './../../Asset/c9.png';
import C10 from './../../Asset/c10.jpg';
import C11 from './../../Asset/c11.png';
import C12 from './../../Asset/c12.jpg';
import C13 from './../../Asset/c13.png';
import C14 from './../../Asset/c14.png';
import C15 from './../../Asset/c15.png';
import C16 from './../../Asset/c16.png';
import C17 from './../../Asset/c17.png';
import C18 from './../../Asset/c18.png';
import C19 from './../../Asset/c19.png';
import C20 from './../../Asset/c20.png';
import C21 from './../../Asset/c21.png';
import C22 from './../../Asset/c22.png';
import C23 from './../../Asset/c23.png';
import C24 from './../../Asset/c24.png';
import C25 from './../../Asset/c25.png';
import C26 from './../../Asset/c26.png';
import C27 from './../../Asset/c27.png';
import C28 from './../../Asset/c28.png';
import C29 from './../../Asset/c29.png';
import C30 from './../../Asset/c30.png';
import C31 from './../../Asset/c31.png';
import C32 from './../../Asset/c32.png';
import C33 from './../../Asset/C33.png';





const Client= () => {
  return (
    <div className='Cli'>

<div className='dpe'>

<h2 className='GLLG'>OUR CLIENTELE</h2>

<div className='C1'>
    <h4 className='kke'> OUR KEY CUSTOMERS</h4>

 <Fade left>   <div className='row'>

 <div className="ic"> <img src={C30} className="cim" /> </div>
 <div className="ic"> <img src={C23} className="cim" /> </div>
 <div className="ic"> <img src={C19} className="cim" /> </div>
 <div className="ic"> <img src={C5} className="cim" /> </div>
 <div className="ic"> <img src={C33} className="cim" /> </div>

    </div> </Fade>


    <Fade left>   <div className='row'>

 <div className="ic"> <img src={C9} className="cim" /> </div>
 <div className="ic"> <img src={C11} className="cim" /> </div>
 <div className="ic"> <img src={C26} className="cim" /> </div>
 <div className="ic"> <img src={C15} className="cim" /> </div>
 <div className="ic"> <img src={C20} className="cim" /> </div>

    </div> </Fade>


    <Fade right>      <div className='row'>

 <div className="ic"> <img src={C27} className="cim" /> </div>
 <div className="ic"> <img src={C32} className="cim" /> </div>
 <div className="ic"> <img src={C22} className="cim" /> </div>
 <div className="ic"> <img src={C4} className="cim" /> </div>
 <div className="ic"> <img src={C21} className="cim" /> </div>

    </div> </Fade>



    <Fade left>   <div className='row'>

 <div className="ic"> <img src={C2} className="cim" /> </div>
 <div className="ic"> <img src={C8} className="cim" /> </div>
 <div className="ic"> <img src={C12} className="cim" /> </div>
 <div className="ic"> <img src={C10} className="cim" /> </div>
 <div className="ic"> <img src={C29} className="cim" /> </div>

    </div> </Fade>


    <Fade left>     <div className='row'>

 <div className="ic"> <img src={C13} className="cim" /> </div>
 <div className="ic"> <img src={C18} className="cim" /> </div>
 <div className="ic"> <img src={C28} className="cim" /> </div>
 <div className="ic"> <img src={C31} className="cim" /> </div>


    </div> </Fade>


</div>

<div className='C2'>
<h4 className='kke'>OUR TRADING PARTNERS</h4>

 <Fade left>   <div className='row'>

 <div className="ic"> <img src={C25} className="cim" /> </div>
 <div className="ic"> <img src={C2} className="cim" /> </div>
 <div className="ic"> <img src={C10} className="cim" /> </div>
 <div className="ic"> <img src={C24} className="cim" /> </div>
 <div className="ic"> <img src={C14} className="cim" /> </div>

    </div></Fade>


    <Fade right>     <div className='row'>

 <div className="ic"> <img src={C6} className="cim" /> </div>
 <div className="ic"> <img src={C1} className="cim" /> </div>
 <div className="ic"> <img src={C7} className="cim" /> </div>
 <div className="ic"> <img src={C16} className="cim" /> </div>
 <div className="ic"> <img src={C29} className="cim" /> </div>

    </div> </Fade>


    <div className='row'>

 <div className="ic"> <img src={C3} className="cim" /> </div>
 <div className="ic"> <img src={C17} className="cim" /> </div>

    </div>


</div>

</div>

        
    </div>
  )
}

export default Client


