import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p5.webp";
import Whatsapp from './../../Components/Whatsapp/Wpp';
import "./P5.css"
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';

const P5 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P5">

<div className='P1c1'>
<h3 className='p1h p1hy'>LOAD BANKS :</h3>
<br />
<Fade left>
<p className='p1hp'>
&emsp;&emsp;Load banks are the test device that is used to generate dummy loads for power supplies. These are a piece of electrical test equipment which is used to: Stimulate electric load. Test an electrical power source, without connecting it to its normal operating load. The device also includes instruments for metering, load control and overload protection.

Load banks are used to test the electrical power supplies. They stimulate the electrical load in a way that can be accurately controlled, measured and recorded. These are used in a variety of applications which includes, Factory testing of turbines. Battery and UPS system testing Ground power testing Load optimizing in prime power applications Load rejection tests Data center tests There are three most common types of load banks available, which include resistive load bank, inductive load bank and capacitive load banks.

The load banks are used for testing replicates and verify the operation of critical power equipment like uninterruptable power supplies, battery system etc.
</p>
</Fade>

</div>


<Fade left>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>
</Fade>

</div>
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P5