import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p4.webp";
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
import "./P4.css"

const P4 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P4">

<div className='P1c1'>
<h3 className='p1h p1hy'>AUTOMATION PANEL :</h3>
<br />
<Fade right>
<p className='p1hp'>
&emsp;&emsp;Automation control panels are one of the most important pieces of equipment for any industrial facility. We all know that machines and equipment across the industrial, manufacturing, and commercial sectors are getting automated. At the same time, such automation must be monitored.

They are used to monitor and control heavy-duty and large-scale machinery in industrial and commercial applications. Automation control panels can perform both simple and complex processes by employing relays, timers, sensors, starters, and controllers. The main advantage of automation control panels is that they provide more control over the industrial equipment in a facility.
</p>

<br />
<h5 className='p1h'>Types Of Automation Control Panels:</h5>

<p className='p1hp'>&emsp;&emsp;These types of control panels have high efficiency and reliability. They are used to accomplish both simple and complex processes. Though used for heavy-duty equipment, PLC control panels have a wide range of applications. PLC control panels offer different options for a protocol which thereby increases their range of applications in the industrial sector.</p>

<br />
<h5 className='p1h'>WHAT CAN YOU EXPECT ?</h5>

<p  className='p1l p1hp'>
<li> Same Day Shipping</li>
<li> Real-Time Emergency Support</li>
<li> A Global Supplying Network</li>

</p>

<br />

<h5 className='p1h'>Still Have Doubts?  Get In Touch Now</h5>

<p className='p1hp'>&emsp;Being a top Electric Power Panel Suppliers in India, we hear your diverse demands and deliver the end-model accordingly. Reach us to take the conversation ahead.</p>

</Fade>

</div>

<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div></Fade>

</div>
    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P4


