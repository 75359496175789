import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p9.webp"
import "./P9.css";
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P9 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
<div className="P9">

<div className='P1c1'>
<h3 className='p1h p1hy'>UPS :</h3>
<br /> <Fade left>
<p className='p1hp'>
&emsp;&emsp;An uninterruptible power supply (UPS), also known as a battery backup, provides backup power when your regular power source fails or voltage drops to an unacceptable level. A UPS allows for the safe, orderly shutdown of a computer and connected equipment. The size and design of a UPS determine how long it will supply power.

The three major types of UPS system configurations are online double conversion, line-interactive and offline (also called standby and battery backup). These UPS systems are defined by how power moves through the unit.
</p>

<br />
<h5 className='p1h'>Online Double Conversion:</h5>

<p className='p1hp'>&emsp;&emsp;AC power is stable and clean upon generation. But during transmission and distribution, it is subject to voltage sags, spikes and complete failure that may interrupt computer operations, cause data loss and damage equipment. When it comes to safeguarding critical IT loads, only online double conversion technology protects fully against all these power problems, providing the highest levels of security for networks.</p>
<br />

<h5 className='p1h'>Line-Interactive:</h5>

<p className='p1hp'>&emsp;Line-interactive UPS systems provide both power conditioning and battery backup. This technology is particularly effective in areas where outages are rare, but power fluctuations are common. Line-interactive UPS systems support a wide range of input voltage fluctuations before switching to battery backup.</p>

<br />

<h5 className='p1h'>Offline/Standby/Battery Backup:</h5>

<p className='p1hp'>&emsp;Offline UPS, also called standby UPS or battery backup, is a cost-effective choice. Better offline UPS systems switch to battery fast enough to prevent power anomalies and ride out short outages. An offline UPS protects against most spikes, but doesn’t maintain perfect power during minor sags and surges.</p>

</Fade>
</div>

<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>
</Fade>

</div>
    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P9