import React from 'react';
import "./Big.css";
import W from "./../../Asset/tran.mov";

const Big = () => {
    return (
        <div className='simulation'>
            <br /> <br />
            <video autoPlay muted loop className='video'>
                <source src={W} type="video/mp4" />
    
        </video>

        <div className='tt'>
            
            <div className='text'>
                <h1 className='bh1'>
                    AMBIONICS serves SIMULATIONS IN TRANSFORMER AND MOTORS</h1>
                <div className='Bp'>
                <p className='Bp1'>
                Do You have to invest in manufacturing of Large Transformers to check the expected results are 
meeting with the design parameters and loose time and energy and scrap it is not meeting the 
expected results? Answer to this is a 
                </p>
                <p className='Bp2'>
          <h2>BIG NO !!!</h2>
                </p>
                <p className='Bp3'>
                NOW you can simulate the complete transformer and test it as if it is physically present 
Please send all your enquiries for simulations and WE shall be glad to help you with the ANSYS 
MAXWELL
                </p>
            </div>
            </div>


            <div className='text1'>
               
                <div className='Bp4'>
                <p className='Bp56'>
                LIMITLESS POSSIBILITIES
                </p>

                <p className='Bp65'>
                To break design and mission boundaries, Ansys gives
engineers the power to see how their ideas will 
perform against millions of variables. 
                </p>
            </div>
            </div>
        </div>

        </div>

    );
}

export default Big;
