import React from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p10.webp"
import "./P10.css"
import { useEffect } from 'react';
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P10 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div>
        <Nav />
              <br/> <br/> <br/> <br/> <br/>
            
              <div className="P10">

<div className='P1c1'>
<h3 className='p1h p1hy'>SPECIAL PURPOSE MACHINES :</h3>
<br /> <Fade left>
<p className='p1hp'>
&emsp;&emsp;Special Purpose machines are an advanced type of programmed and automated types of equipment designed according to the requirements of the clients. There have been many innovative quests to make excellent special purpose machines, in order to maximize productivity. These special types of machinery are generally used for mass production of metal cutting. It also helps to be up to date with the quality of the products.

The demand for the special purpose machines increases with the popularity of the Industrial Revolution and automating in the process. These machines proved to be great to enhance efficiency in production, enabled quality mass productions and too in affordable prices as compared to standard CNC machines.
They are gaining popularity in building automation and are inescapable parts of diverse vending machines, information kiosks, etc.
</p>
</Fade>

</div>

<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>
</Fade>
</div>
        
<Whatsapp></Whatsapp>
<Cb />
            <Foot /></div>
    )
}

export default P10;