import React from 'react';
import { useState } from "react";
import "./Nav.css";
import  {Link}  from 'react-router-dom';

import logo2 from './../../Asset/logo192.png';
import Fb from "./../../Asset/facebook.png";
import Insta from './../../Asset/instagram.png';
import twitter from './../../Asset/twitter.png';
import Li from './../../Asset/linkedin.png';
import Yt from './../../Asset/youtube.png';
import Fade from 'react-reveal/Fade';

function Navi() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

    const resetNavbar = () => {
      setActive('nav__menu');
      setIcon('nav__toggler');
   
  };
  return (
    <div className="nasv">
    <nav className="nav">
    
<div className='header'>

    <div className='h1'><a href="tel:9822431299" className='mail mail1'>Phone : +91 9822431299</a>
    &emsp;&emsp;&emsp;
 <a href="mailto:sales@ambionicsindia.com" className='mail mail2'>Email : sales@ambionicsindia.com</a>
 </div>
    
    <div className='ss'>

    <a href='https://www.facebook.com/profile.php?id=100079784208988' ><img src={Fb} className='ims'></img></a>
    <a href='https://www.instagram.com/ambionics_india/' ><img src={Insta} className='ims'></img></a>
    <a href='https://www.linkedin.com/company/3274762/admin/feed/posts/' ><img src={Li} className='ims'></img></a>

    </div>
</div>


<div>


<div className='nd'>

<div className={active}>
      <ul className='ul'>
      <li className="list"><Link to='/' className="list" onClick={resetNavbar}>HOME</Link></li>
      <li className="list"><Link to='/About' className="list" onClick={resetNavbar}>ABOUT</Link></li>
      <li className="list"><Link to='/Activities' className="list" onClick={resetNavbar}>ACTIVITIES</Link></li>
      <li>
         <div class="dropdown">
           <li className="list"><Link to='/Products' className="list" onClick={resetNavbar}>PRODUCTS <span className='derr'>⌄</span></Link></li>
              <div className="dropdown-content">
          
               <li className="list1"> <Link to='/PowerPanel' onClick={resetNavbar}> POWER PANEL</Link></li>  
               <li className="list1">  <Link to='/ControlPanel' onClick={resetNavbar}>CONTROL PANEL</Link></li>   
               <li className="list1">  <Link to='/GeneratorPanels' onClick={resetNavbar}> GENERATOR PANEL</Link></li>  
               <li className="list1">  <Link to='/AutomationPanels' onClick={resetNavbar}>AUTOMATION PANEL </Link></li> 
               <li className="list1">  <Link to='/LoadBank' onClick={resetNavbar}>LOAD BANK </Link></li>
               <li className="list1">  <Link to='/PLCPanel' onClick={resetNavbar}>PLC PANEL </Link></li>
               <li className="list1">  <Link to='/OperatorPanels' onClick={resetNavbar}>OPERATOR PANEL</Link></li>
               <li className="list1">  <Link to='/BatteryCharger' onClick={resetNavbar}>BATTERY CHARGER </Link></li>
               <li className="list1">  <Link to='/UPS' onClick={resetNavbar}> UPS </Link></li>
               <li className="list1">  <Link to='/SpecialPurposeMacines' onClick={resetNavbar}> SPECIAL PURPOSE MACHINE </Link></li>
               <li className="list1">  <Link to='/HydroTestingMacine' onClick={resetNavbar}>HYDRO TESTING MACHINE </Link></li>                        
               <li className="list1">  <Link to='/BushPressingMachine' onClick={resetNavbar}>BUSH PRESSING MACHINE </Link></li>
               <li className="list1">  <Link to='/NutRunnerMachine' onClick={resetNavbar}>NUT RUNNER MACHINE </Link></li>
               <li className="list1">  <Link to='/AssemblyFIxtures' onClick={resetNavbar}>ASSEMBLY FIXTURES </Link></li>
                   </div>
              </div>

         </li>

         <li className='l3'>

<img src={logo2} alt="ambionics" className='img1'></img>

         </li>


        <li className="list"><Link to='/Download' className="list" onClick={resetNavbar}>CATALOGUE</Link></li>
        <li className="list"><Link to='/Clientele' className="list" onClick={resetNavbar}>CLIENTELE</Link></li>
        <li className="list"><Link to='/Gallry' className="list" onClick={resetNavbar}>GALLERY</Link></li>
        <li className="list"><Link to='/Contact' className="list" onClick={resetNavbar}>CONTACT US</Link></li>

      </ul>
      </div>
      </div>
      </div>
<div>
      <marquee className="mq"> CONTROL PANEL, ELECTRICAL POWER PANEL & LOAD BANKS MANUFACTURERS IN PUNE SINCE 1987 </marquee>
      </div>


      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>


    </div>
  );
}

export default Navi;