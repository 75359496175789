import './Patron.css';
import Fade from 'react-reveal/Fade';

const Patron = (props) => {
    return <div className="patcontain">
      
            <div className="patimgHold">
                <img src={props.img} className="patimg"/>
            </div>
        </div>
};

export default Patron;