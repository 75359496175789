import A from "./../../Asset/slider1.jpg"
import B from "./../../Asset/slider2.jpg"
import C from "./../../Asset/slider3.jpg"
import D from "./../../Asset/slider4.webp"
import E from "./../../Asset/slider5.webp"
import Fade from 'react-reveal/Fade';
import React, { useRef, useEffect } from 'react';
import './Slider.css'; // Import your CSS file

const Slider = () => {
  const imgContainerRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (imgContainerRef.current) {
        let last = imgContainerRef.current.firstElementChild;
        last.remove();
        imgContainerRef.current.appendChild(last);
      }
    }, 2500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='slider-container'>
      <div className='img-container' ref={imgContainerRef}>
        <div className='box'>
          <img src={A} className='si' alt='ambionics' />
        </div>
        <div className='box'>
          <img src={B} className='si' alt='ambionics' />
        </div>
        <div className='box'>
          <img src={C} className='si' alt='ambionics' />
        </div>
        <div className='box'>
          <img src={D} className='si' alt='ambionics' />
        </div>
        <div className='box'>
          <img src={E} className='si' alt='ambionics' />
        </div>
      </div>
    </div>
  );
};

export default Slider;
