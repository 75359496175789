import React from 'react';
import { useEffect } from 'react';
import Client from "./../Components/Client/Client"
import Nav from './../Components/Nav/Nav';
import Foot from '../Components/Footer/Foot';
import Whatsapp from './../Components/Whatsapp/Wpp';
import Cb from './../Components/CB/Cb'
const Clients = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
        <Nav />
        <br/> <br/> <br/> <br/>
        <Client />
        <Whatsapp></Whatsapp>
        <Cb />
        <Foot />
    </div>
  )
}

export default Clients