import React, { useState } from 'react';
import './Gallery.css';
import Fade from 'react-reveal/Fade';

import G1 from './../../Asset/g1.jpg';
import G2 from './../../Asset/g2.jpg';
import G3 from './../../Asset/g3.png';
import G4 from './../../Asset/g4.png';
import G5 from './../../Asset/g5.jpg';

import G7 from './../../Asset/g7.jpg';
import G8 from './../../Asset/g8.jpg';
import G9 from './../../Asset/g9.jpg';
import G10 from './../../Asset/g10.jpg';
import G11 from './../../Asset/g11.jpg';
import G12 from './../../Asset/g12.jpg';
import G13 from './../../Asset/g13.jpg';
import G14 from './../../Asset/g14.jpg';
import G19 from './../../Asset/g15.jpg';
import G15 from './../../Asset/g16.jpg';
import G16 from './../../Asset/g17.jpg';
import G17 from './../../Asset/g18.jpg';

import P1 from "./../../Asset/p1.webp";
import P2 from "./../../Asset/p2.webp";
import P3 from "./../../Asset/p3.webp";
import P4 from "./../../Asset/p4.webp";
import P5 from "./../../Asset/p5.webp";
import P6 from "./../../Asset/p6.webp";
import P7 from "./../../Asset/p7.webp";
import P8 from "./../../Asset/p8.webp";
import P9 from "./../../Asset/p9.webp";
import P10 from "./../../Asset/p10.webp";
import P11 from "./../../Asset/p11.webp";
import P12 from "./../../Asset/p12.webp";
import P13 from "./../../Asset/p13.webp";
import P14 from "./../../Asset/p14.webp";

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
  
    const imageList = [
        G1, G2, G3, G4, G5,
        G7, G8, G9, G10, G11,
        G12, G13, G14, G15, G16,
        G17, G19, P1, P2, P3, P4, P5,
        P6, P7, P8,P9, P10, P11, P12,
        P13,P14
    ];
  
    const openImage = (image) => {
        setSelectedImage(image);
    };
  
    const closeImage = () => {
        setSelectedImage(null);
    };
  
    return (
      <div className='j1'>
        <div className='nj'>
          <br /><br />  <br /><br />
          <h2 className='gh2'>GALLERY</h2>
          <br />
          <div className="thumb-bar">
            {imageList.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className='gi'
                onClick={() => openImage(image)}
              />
            ))}
          </div>
        </div>
  
        {selectedImage && (
          <div className="modal-overlay" onClick={closeImage}>
            <div className="modal-content">
              <img
                src={selectedImage}
                alt="Selected Image"
                className="selected-img"
              />
            
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default Gallery;
