import React from 'react'
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p13.webp"
import "./P13.css"
import { useEffect } from 'react';
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P13 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P13">

<div className='P1c1'>
<h3 className='p1h p1hy'>NUT RUNNER MACHINE :</h3>
<br></br> <Fade left>
<p className='p1hp'>
&emsp;&emsp;We are engaged in trading and supplying of world class Nut Runner Machine. Quite well appreciated by our clients, the offered runner provides a smooth screw in/out of nuts and bolts. With wide range of application like in automobiles and varied other mechanical industries, the provided runner is promised to deliver lateral consistent quality. Our esteemed clients can avail our vivid runner at market leading prices.
</p>
<br />

<h5 className='p1h'>FEATURES</h5>

<p  className='p1l p1hp'>
<li> HIGH ACCURACY</li>
<li> EASY TO USE</li>
<li> RESISTANT AGAINST CORROSION</li>

</p>

</Fade>

</div>
<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>
</Fade>
</div>
    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P13