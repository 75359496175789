import React from 'react'
import A from "./../../Asset/w2.png";
import B from "./../../Asset/w3.png";
import C from "./../../Asset/w1.png";
import Fade from 'react-reveal/Fade';

import "./Wcu.css";

const Wcu = () => {
  return (
    <div>
      <h2 className='hed'>WHY CHOOSE US</h2>
      <div className='f2'>

      <Fade left>   
      <div className='me'>
      <div className='de'>
    <img src={A} className="ci"></img>
<h4 className='hdd'>QUALITY OF PRODUCTS </h4>
<p className='cp'>Our brilliance is defined by our quality, and we never sacrifice the caliber of our output.</p>
    </div>
    </div>
</Fade>
<Fade left>   

    <div className='me'>
<div className='de'>
<img src={B} className="ci"></img>
<h4 className='hdd'>WE VALUE ETHICS</h4>
<p className='cp'>We always keep lines of communication open and honest with our clients to prevent future disputes.</p>
    </div>
 </div>
</Fade>

<Fade right>   
    <div className='me'>
<div className='de'>
<img src={C} className="ci"></img>
<h4 className='hdd'> TRADING SERCVICES </h4>
<p className='cp'>We want to establish a lasting relationship with our consumers because we value them.</p>
    </div>
</div>
</Fade>

<Fade right>
<div className='me'>
<div className='de'>
<img src={C} className="ci"></img>
<h4 className='hdd'>  MISSION AND VISION  </h4>
<p className='cp'>Transforming industrial control systems by leveraging personnel expertise, ensuring efficiency, and delivering value in technology supply.</p>
    </div>
</div></Fade>

      </div>
      
      </div>
  )
}

export default Wcu