import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p6.webp"
import "./P6.css"
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P6 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P6">

<div className='P1c1'>
<h3 className='p1h p1hy'>PLC MANUFACTURERS :</h3>
<br />
<Fade left>
<p className='p1hp'>
&emsp;&emsp;PLC Panel is a special steel box that contains electrical components required to run a factory machine or process. PLC stands for Programmable Logic Controller, an industrial hardened computer usually found inside factory control panels. High voltage components are what makes up a lot of the other items found inside.

PLC Panels are used in variety of electronic and electrical circuit fittings for a variety of applications which can be broadly divided into 3 categories:
</p>
<br />
<h5 className='p1h'>OEM CONTROL PANEL</h5>

<p className='p1hp'>&emsp;&emsp;Which covers applications such as wind turbine panels, compressor control panel, incinerators, boilers, chillers, spray driers, HVAC panels, etc.</p>

<br />
<h5 className='p1h'>ELECTRICAL PANELS</h5>

<p className='p1hp'>&emsp;Covering MCC, PDB, Change-over panel, etc.</p>

<br />
<h5 className='p1h'>INSTRUMENTATION / AUTOMATOMATION PANEL</h5>

<p className='p1hp'>&emsp;Such as VFD panel, computer panel, monitor, pressurisation, SCADA systems</p>
</Fade>

</div>

<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>
</Fade>

</div>
    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P6