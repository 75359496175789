import React from 'react';
import "./Abt.css";
import Fade from 'react-reveal/Fade';
const Abt = () => {
  return (
    <div className='container'>
      <div className='abt'>
      <Fade right>    <div className="ab1"></div> </Fade>
      <Fade right>        <div className="ab2"></div> </Fade>
           <div className="ab3"> 
          <div className='ab31'>
          <Fade left>   <h4 className='abh'>ABOUT US</h4> </Fade>
            <p className='abp'>
            <Fade left>       &emsp;&emsp;Ambionics was established in 1987 and is known as a leading company that manufactures electronic control panels in Pune, Maharashtra. We have a wide range of products to suit all types of applications and industries, and our team of experts is always on hand to offer advice and support. We pride ourselves on our quality products and service, and we are always looking to innovate and improve our offering.
              Our company is ISO 9001 certified, which means that we adhere to the highest international standards for quality management. This certification is recognized worldwide, and we are proud to have many happy customers in countries all over the world. Our commitment to quality is evident in everything we do, from the products we manufacture to the services we provide.
              <br></br> &emsp; &emsp; We are constantly striving to exceed our customers' expectations, and we are always looking for ways to improve our quality management system. We are confident that our ISO 9001 certification will continue to give our customers the peace of mind that they are dealing with a reputable and reliable company.
              We are the largest manufacturer of power panels, control panels, generator panels, etc. in the world. We have been in business for more than 35 years and have an excellent reputation for quality and service. We offer a wide variety of power panel products to meet the needs of our customers. Our products are used in a variety of applications, including residential, commercial, industrial, and military.   </Fade>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Abt;
