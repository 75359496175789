import React from 'react';
import { useEffect } from 'react';
import Nav from './../Components/Nav/Nav';
import Foot from '../Components/Footer/Foot';
import Cnt from './../Components/Contact/Cnt';
import Whatsapp from './../Components/Whatsapp/Wpp';

const Contact = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div>
    <Nav></Nav>
      <br/> <br/> <br/> <br/><br />
    <Cnt />
    <Whatsapp></Whatsapp>
    <Foot />


        </div>
    )
}

export default Contact;