import React from 'react';
import { useEffect } from 'react';
import What from "./../Components/Whatd/What"
import Whatsapp from './../Components/Whatsapp/Wpp';
import Nav from './../Components/Nav/Nav';
import Foot from './../Components/Footer/Foot';
import Cb from './../Components/CB/Cb'

const Activities = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
                  
      <Nav></Nav>
      <br/> <br/> <br/> 
      <br/><br/>
        <What />
        <Whatsapp></Whatsapp>
        <Cb />
        <Foot />
    </div>
  )
}

export default Activities