import React from 'react'
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p12.webp"
import "./P12.css"
import { useEffect } from 'react';
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P12 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
          
          <div className="P12"> 
          <div className='P1c1'>
<h3 className='p1h p1hy'>BUSH PRESSING MACHINE:</h3>
<br /> <Fade left>
<p className='p1hp'>
&emsp;&emsp;Our presented series of Bush Press Machine is enormously demanded in the market for their long-lasting nature and superior finish.

We are able to manufacture superior quality Pressing Bush Pressing Machine. These Pressing SPM are huge demand in the market due to high quality and durability. These products are available at competitive rates.
</p>

<br />

<h5 className='p1h'>Details :</h5>

<p className='p1hp'>&emsp;&emsp;Monitor pressing process for load at different positions and reject job if deviation beyond set band
With Load Monitoring</p>

</Fade>
</div>  

<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div></Fade>
</div>
    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P12