import React from 'react'
import { useEffect } from 'react';

import Nav from './../Components/Nav/Nav'
import Info from './../Components/Home/Info'
import Wwd from './../Components/Home/Wwd'
import Wcu from './../Components/Home/Wcu'
import Slider from '../Components/Home/Slider'
import Big from '../Components/Home/Big'
import Product from '../Components/Home/Product'
import Foot from '../Components/Footer/Foot'
import Clients from './../Components/Home/Cli';
import Whatsapp from './../Components/Whatsapp/Wpp';
import Cb from './../Components/CB/Cb'


const Home = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
   <Nav></Nav>
   <br/> <br/> <br/> <br/> <br /><br />
   <Slider></Slider>
   <Info></Info>
 <Big />
   <Wwd />
   <Wcu />
   <Clients />
   <Product />
      <Cb />
      <Whatsapp />
 
<Foot />
    </div>
  )
}

export default Home