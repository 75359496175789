import React from 'react'
import G9 from './../../Asset/g9.jpg';
import G10 from './../../Asset/g10.jpg';
import G11 from './../../Asset/g11.jpg';
import G12 from './../../Asset/g12.jpg';
import G13 from './../../Asset/g13.jpg';
import G14 from './../../Asset/g14.jpg';
import "./Expertise.css"
import Fade from 'react-reveal/Fade';


const Expertise = () => {
  return (
    <div className='Exp'>

<div className='exp1'>
<Fade right>   <h4 className='exp1h'>OUR EXPERTISE</h4>

<p className='exp1p'>&emsp; &emsp;With over 35 years of experience, Ambionics stands as a premier electronic control panels manufacturer in Pune, Maharashtra. Our ISO 9001 certification reflects our unwavering commitment to delivering high-quality products globally, ensuring customer satisfaction across diverse applications and industries.
We are leading Control Panel Manufacturer in Pune, offering three decades of expertise in electronics, electrical, and automation solutions. As an ISO 9001-certified company with a global clientele of over 1000 satisfied customers, we specialize in manufacturing high-quality Electrical & Engineering Products, providing turnkey solutions for power panels. Recognized for our commitment to innovation and excellence, Ambionics stands out as a renowned Electrical Power Panel Manufacturer in Maharashtra.
</p></Fade>
</div>
<br />
<Fade left>
<div className='exp2'>
    <img src={G13} className="exp2i"></img>
</div>
</Fade>

    </div>
  )
}

export default Expertise