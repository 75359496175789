import React from 'react'
import "./Prdct.css"
import Fade from 'react-reveal/Fade';

import P1 from "./../../Asset/p1.webp";
import P2 from "./../../Asset/p2.webp";
import P3 from "./../../Asset/p3.webp";
import P4 from "./../../Asset/p4.webp";
import P5 from "./../../Asset/p5.webp";
import P6 from "./../../Asset/p6.webp";
import P7 from "./../../Asset/p7.webp";
import P8 from "./../../Asset/p8.webp";
import P9 from "./../../Asset/p9.webp";
import P10 from "./../../Asset/p10.webp";
import P11 from "./../../Asset/p11.webp";
import P12 from "./../../Asset/p12.webp";
import P13 from "./../../Asset/p13.webp";
import P14 from "./../../Asset/p14.webp";

const Prdct = () => {
  return (
    <div className='prdct1'><br />  <br /><br />
<h4 className='PRd'>OUR PRODUCTS</h4>      
<br />
<div className='prdct2'>

<a href="/PowerPanel" className='prdli'><div> <div className='pdimd'><img src={P1} className="pdim"/></div> <h5 className='prdh5'> POWER PANEL</h5></div></a>
<a href="/ControlPanel" className='prdli'><div> <div className='pdimd'><img src={P2} className="pdim"/></div> <h5 className='prdh5'>CONTROL PANEL</h5></div></a>
<a href="/GeneratorPanels" className='prdli'><div> <div className='pdimd'><img src={P3} className="pdim"/></div> <h5 className='prdh5'>GENERATOR PANEL</h5></div></a>
<a href="/AutomationPanels" className='prdli'><div> <div className='pdimd'><img src={P4} className="pdim"/></div> <h5 className='prdh5'>AUTOMATION PANEL</h5></div></a>
<a href="/LoadBank" className='prdli'><div> <div className='pdimd'><img src={P5} className="pdim"/></div> <h5 className='prdh5'>LOAD BANK</h5></div></a>

</div>

<div className='prdct2'>

<a href="/PLCPanel" className='prdli'><div> <div className='pdimd'><img src={P6} className="pdim"/></div> <h5 className='prdh5'>PLC PANEL</h5></div></a>
<a href="/OperatorPanels" className='prdli'><div> <div className='pdimd'><img src={P7} className="pdim"/></div> <h5 className='prdh5'>OPERATOR PANEL</h5></div></a>
<a href="/BatteryCharger" className='prdli'><div> <div className='pdimd'><img src={P8} className="pdim"/></div> <h5 className='prdh5'>BATTERY CHARGER</h5></div></a>
<a href="/UPS" className='prdli'><div> <div className='pdimd'><img src={P9} className="pdim"/></div> <h5 className='prdh5'>UPS</h5></div></a>
<a href="/SpecialPurposeMacines" className='prdli'><div> <div className='pdimd'><img src={P10} className="pdim"/></div> <h5 className='prdh5'>SPECIAL PURPOSE MACHINES</h5></div></a>

</div>


<div className='prdct2'>

<a href="/HydroTestingMacine" className='prdli'><div> <div className='pdimd'><img src={P11} className="pdim"/></div> <h5 className='prdh5'>HYDRO TESTING MACHINE</h5></div></a>
<a href="/BushPressingMachine" className='prdli'><div> <div className='pdimd'><img src={P12} className="pdim"/></div> <h5 className='prdh5'>BUSH PRESSING MACHINE</h5></div></a>
<a href="/NutRunnerMachine" className='prdli'><div> <div className='pdimd'><img src={P13} className="pdim"/></div> <h5 className='prdh5'>NUT RUNNER MACHINE</h5></div></a>
<a href="/AssemblyFIxtures" className='prdli'><div> <div className='pdimd'><img src={P14} className="pdim"/></div> <h5 className='prdh5'>ASSEMBLY FIXTURES</h5></div></a>

</div>


    </div>
  )
}

export default Prdct