import React from 'react';
import "./Product.css";
import Fade from 'react-reveal/Fade';
import P1 from "./../../Asset/p1.webp";
import P2 from "./../../Asset/p2.webp";
import P3 from "./../../Asset/p3.webp";
import P4 from "./../../Asset/p4.webp";
import P5 from "./../../Asset/p5.webp";
import P6 from "./../../Asset/p6.webp";
import P7 from "./../../Asset/p7.webp";
import P8 from "./../../Asset/p8.webp";
import P9 from "./../../Asset/p9.webp";
import P10 from "./../../Asset/p10.webp";
import P11 from "./../../Asset/p11.webp";
import P12 from "./../../Asset/p12.webp";
import P13 from "./../../Asset/p13.webp";
import P14 from "./../../Asset/p14.webp";


const Product = () => {
  return (
    <div className='products'>
  <h2 className='hed'>OUR PRODUCTS</h2>
      
  <Fade left>   <div className='pl1'>

<div className='pr'>
     <div><img src={P1} alt="Amboinics"  className='pi' /></div>
    <div className="ph"> <h6 > POWER PANEL </h6></div>
    <a href="/PowerPanel" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P2} alt="Amboinics"  className='pi' /> </div>
      <div className="ph"><h6> CONTROL PANEL </h6></div>
     <a href="/ControlPanel" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P3} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> GENERATOR PANEL </h6></div>
     <a href="/GeneratorPanels" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P4} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> AUTOMATION PANEL </h6></div>
     <a href="/AutomationPanels" className='btn' > VIEW MORE</a>
</div>
<div className='pr'>
      <div><img src={P5} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> LOAD BANK </h6></div>
     <a href="/LoadBank" className='btn' > VIEW MORE</a>
</div>


      </div></Fade>


      <Fade right>   <div className='pl1'>

<div className='pr'>
      <div><img src={P6} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> PLC PANEL </h6></div>
     <a href="/PLCPanel" className='btn' > VIEW MORE</a>
</div>
<div className='pr'>
      <div><img src={P7} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> OPERATOR PANEL </h6></div>
     <a href="/OperatorPanels" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P8} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> BATTERY CHARGER </h6></div>
     <a href="/BatteryCharger" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P9} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> UPS </h6></div>
     <a href="/UPS" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P10} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> SPECIAL PURPOSE MACHINE </h6></div>
     <a href="/SpecialPurposeMacines" className='btn' > VIEW MORE</a>
</div>




      </div> </Fade>


      <Fade left>     <div className='pl1'>
<div className='pr'>
      <div><img src={P11} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> HYDRO TESTING MACHINE </h6></div>
     <a href="/HydroTestingMacine" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P12} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> BUSH PRESSING MACHINE</h6></div>
     <a href="/BushPressingMachine" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P13} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6>NUT RUNNER MACHINE </h6></div>
     <a href="/NutRunnerMachine" className='btn' > VIEW MORE</a>
</div>

<div className='pr'>
      <div><img src={P14} alt="Amboinics"  className='pi' /></div>
      <div className="ph"><h6> ASSEMBLY FIXTURES </h6></div>
     <a href="/AssemblyFIxtures" className='btn' > VIEW MORE</a>
</div>


</div> </Fade>   
    </div>
  )
}

export default Product