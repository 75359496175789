import React from 'react';
import { useEffect } from 'react';
import Abt from "./../Components/About/Abt"
import Nav from './../Components/Nav/Nav';
import Foot from './../Components/Footer/Foot';
import Cntr from './../Components/About/Counter';
import Whatsapp from './../Components/Whatsapp/Wpp';
import Cb from './../Components/CB/Cb'
import Exp from "./../Components/About/Expertise"
import Goa from "./../Components/About/Success"

const About = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>

      <Nav />
      <br/> <br/> <br/> <br/> <br></br>
      <Abt />
<Cntr  end={50}></Cntr>
<Exp />
<Goa />
<Whatsapp></Whatsapp>
<Cb />
<Foot />

    </div>
  )
}

export default About