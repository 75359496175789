import React from 'react'
import "./Cnt.css";
import Fade from 'react-reveal/Fade';

const Cnt = () => {
  return (
    <div className='gblk'>
  <br /><br />
<h4 className='GLLd'>CONTACT US ...</h4>
<div className='cn'>
<Fade left>   
<div className='cn1'>

    <h5 className='kke'>ADDRESS :</h5><br /><br /><br />

    <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.694010857791!2d73.7942993724072!3d18.452197182628087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2950a58df56eb%3A0x1c8332e7a920b22b!2sAMBIONICS!5e0!3m2!1sen!2sin!4v1701341635893!5m2!1sen!2sin" className='cnp'> SHED NO 3 S NO 134/1/2/1 DALVIWADI DHAYRI PUNE NEAR NANDED PHATA BEHIND BIOMARK LABORATORY PUNE 411041</a>

    <br />

    <h5 className='kke'>EMAIL :</h5><br /><br /><br />

<a href="mailto:sales@ambionicsindia.com" className='cnp'>sales@ambionicsindia.com</a><br />

<br />

<h5 className='kke'>PHONE :</h5><br /><br /><br />

<a href="tel:9822431299" className='cnp'>+91 9822431299</a>
<br />
</div>
</Fade>


<Fade right>
<div className='cn2'>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.694010857791!2d73.7942993724072!3d18.452197182628087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2950a58df56eb%3A0x1c8332e7a920b22b!2sAMBIONICS!5e0!3m2!1sen!2sin!4v1701341635893!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="srs"></iframe>
</div>
</Fade>
</div>
        
    </div>
  )
}

export default Cnt