import React from 'react'
import classes from "./Blogs.module.css"
import P1 from "./f.jpeg"
const Blogs = () => {
  return (
    <div className={classes.blogs}>


       <h1 className={classes.heding}> Blogs </h1>

            <br/>

        <div className={classes.bgs}>

<div className={classes.box}>
<div><img src={P1} alt="Amboinics"  className={classes.pi} /></div>

<h5 className={classes.ph} > Pune Real Estate Market Forecast & Trends 2024: Exploring Future Opportunities and insights</h5>
   
   <p className={classes.dt}>February 09, 2024</p>
   <br/>
    <a href="/Blog" className={classes.btn} >READ MORE</a>
    </div>


    <div className={classes.box}>
<div><img src={P1} alt="Amboinics"  className={classes.pi} /></div>

<h5 className={classes.ph} > Pune Real Estate Market Forecast & Trends 2024: Exploring Future Opportunities and insights</h5>
   
   <p className={classes.dt}>February 09, 2024</p>
   <br/>
    <a href="/Blog" className={classes.btn} >READ MORE</a>
    </div>

    <div className={classes.box}>
<div><img src={P1} alt="Amboinics"  className={classes.pi} /></div>

<h5 className={classes.ph} > Pune Real Estate Market Forecast & Trends 2024: Exploring Future Opportunities and insights</h5>
   
   <p className={classes.dt}>February 09, 2024</p>
   <br/>
    <a href="/Blog" className={classes.btn} >READ MORE</a>
    </div>

     <div className={classes.box}>
<div><img src={P1} alt="Amboinics"  className={classes.pi} /></div>

<h5 className={classes.ph} > Pune Real Estate Market Forecast & Trends 2024: Exploring Future Opportunities and insights</h5>
   
   <p className={classes.dt}>February 09, 2024</p>
   <br/>
    <a href="/Blog" className={classes.btn} >READ MORE</a>
    </div>

     <div className={classes.box}>
<div><img src={P1} alt="Amboinics"  className={classes.pi} /></div>

<h5 className={classes.ph} > Pune Real Estate Market Forecast & Trends 2024: Exploring Future Opportunities and insights</h5>
   
   <p className={classes.dt}>February 09, 2024</p>
   <br/>
    <a href="/Blog" className={classes.btn} >READ MORE</a>
    </div>
</div>


        </div>

  
  )
}

export default Blogs