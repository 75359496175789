import React from 'react'
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p11.webp"
import "./P11.css";
import { useEffect } from 'react';
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P11 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div>
        <Nav />
              <br/> <br/> <br/> <br/> <br/>
            
<div className="P11">

<div className='P1c1'>
<h3 className='p1h p1hy'>HYDRO TESTING MACHINE :</h3>
<br></br> <Fade left>
<p className='p1hp'>
&emsp;&emsp;Hydro testing machine are common procedure used to check fluid pressure vessel, such as, cylinders, boiler, tubes pipe, valves, castings, components etc. Hydrostatic testing machine is also a common testing procedure to verify pressure vessels do not leak or have manufacturing flaws. Hydro testing machine & unit is important for pressure vessels in the interest of device safety, and durability under operating pressure.

Hydro-testing Machines also offers hydro testing machine with pre-filling cum pre-pressurizing cum pressure building system. For large volume of hydro test combine hydrostatic pressure testing unit is most preferred solution. This hydro testing machine & equipment is compact, efficient & cost effective, available in different combinations of pressure and flow.
</p></Fade>


</div>
<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div></Fade>

</div>
<Whatsapp></Whatsapp>
<Cb />
        
            <Foot /></div>
    )
}

export default P11;