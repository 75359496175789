import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p8.webp";
import "./P8.css"
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Fade from 'react-reveal/Fade';
import Cb from './../CB/Cb'
const P8 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P8">

<div className='P1c1'>
<h3 className='p1h p1hy'>BATTERY CHARGER :</h3>
<br /><Fade left>
<p className='p1hp'>
&emsp;&emsp;The Operator Panel enables interaction between humans and machines and makes the Human Machine Interface (HMI). The panels usually include LCD display with an attached touch display, which replaces old fashioned HMIs with bulky push-buttons, levers, analog indicators, etc.

Operator panels play an important role in the field of industrial automation and can be found on every factory floor. Besides the industrial automation, the operator panels can be found in other industrial sectors as well.
They are gaining popularity in building automation and are inescapable parts of diverse vending machines, information kiosks, etc.
</p>
</Fade>

</div>
<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div></Fade>

</div>
    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P8