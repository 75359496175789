import { useEffect, useRef, useState } from 'react';
import  './Counter.css';
import Fade from 'react-reveal/Fade';



const Num = ({ end = 50 }) => {
    const [state, setState] = useState(null);
    const ref = useRef(0);

    const accumulator = end / 200;

    const updatecounterState = () => {
        if (ref.current < end) {
            const result = Math.ceil(ref.current + accumulator);

            if (result > end) return setState(end);
            setState(result);
            ref.current = result;
        }
        setTimeout(updatecounterState, 500)
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) { updatecounterState() };

        return () => isMounted = false;
    }, [end]);



    return <div>
        <div className="container1">
            <div className="hold">
          
        <Fade right> <div><span className="num">{state + 1937}</span></div>
                <div><span  id="k">ESTABLISHED YEAR</span></div>    </Fade>        
          
            </div>
            <div className="hold1">
           
            <Fade right>  <div><span className="num">{34}+</span></div>
                <div><span  id="k">YEARS OF EXPERIENCE</span></div> </Fade>
         
            </div>
            <div className="hold1">
           
            <Fade left>   <div><span className="num">{state + 2950}+</span></div>
                <div><span  id="k">COMPLETED PROJECT</span></div> </Fade>
           
            </div>
            <div className="hold1">
        
            <Fade left>  <div><span className="num">{state + 950}+</span></div>
                <div><span  id="k">HAPPY CUSTOMER</span></div>  </Fade>
             
            </div>

        </div >
 
    </div>
};

export default Num;