import React from 'react'
import "./Cb.css";
import Fade from 'react-reveal/Fade';

const Cb = () => {
  return (
    <div className='cb'> 
    <Fade left>
<div className='cb1'>
    <h3>FOR ANY QUERIES, FEEL FREE TO CONTACT US</h3>
</div></Fade>

<Fade right>
<div className='cb2'>
<a href="tel:9822431299" className='BBD1'><button className='BBD'> CALL US</button></a>
<a href="mailto:sales@ambionicsindia.com" className='BBD1'><button className='BBD'> MAIL US</button></a>
</div></Fade>
    </div>
  )
}

export default Cb