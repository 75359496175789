import React from 'react'
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p14.webp"
import "./P14.css"
import { useEffect } from 'react';
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P14 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P14">

<div className='P1c1'>
<h3 className='p1h p1hy'>ASSEMBLY FIXTURES :</h3>
<br />
<Fade left>
<p className='p1hp'>
&emsp;&emsp;A fixture is a work-holding or support device used in the manufacturing industry. Fixtures are used to securely locate (position in a specific location or orientation) and support the work, ensuring that all parts produced using the fixture will maintain conformity and interchangeability. Using a fixture improves the economy of production by allowing smooth operation and quick transition from part to part, reducing the requirement for skilled labor by simplifying how work pieces are mounted, and increasing conformity across a production run. A fixture differs from a jig in that when a fixture is used, the tool must move relative to the work piece; a jig moves the piece while the tool remains stationary.

Fixtures also allow for a higher degree of operator safety by reducing the concentration and effort required to hold a piece steady. Economically speaking the most valuable function of a fixture is to reduce labor costs. Without a fixture, operating a machine or process may require two or more operators; using a fixture can eliminate one of the operators by securing the workpiece.
</p>

</Fade>
</div>
<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>
</Fade>
</div>
    
    
<Whatsapp></Whatsapp>
<Cb />
        <Foot /></div>
  )
}

export default P14