import React  from 'react';
import { useEffect } from 'react';
import Ga from './../Components/Gallery/Gallery';
import Nav from './../Components/Nav/Nav';
import Foot from '../Components/Footer/Foot';
import Whatsapp from './../Components/Whatsapp/Wpp';
import Cb from './../Components/CB/Cb'

const Gallery = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
    return(
        <div> 
            
      <Nav></Nav>
      <br/> <br/> <br/> <br/>
    <Ga> </Ga>
    <Whatsapp></Whatsapp>
    <Cb />
    <Foot />

            </div>
    )
}

export default Gallery;