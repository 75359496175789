import React from 'react'
import Prdct from '../Components/Product/Prdct'
import Nav from './../Components/Nav/Nav';
import Foot from '../Components/Footer/Foot';
import { useEffect } from 'react';
import Whatsapp from './../Components/Whatsapp/Wpp';
import Cb from './../Components/CB/Cb'

const Products = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
<Nav />
      <br/> <br/> <br/> <br/>
    <Prdct />
    <Whatsapp></Whatsapp>
    <Cb />
    <Foot />

    </div>
  )
}

export default Products