import React from 'react'
import G11 from './../../Asset/g11.jpg';
import G12 from './../../Asset/g12.jpg';
import "./Success.css"
import Fade from 'react-reveal/Fade';

const Success = () => {
  return (
    <div>
          <div className='Exp'>

          <Fade right>     <div className='exp2'>
    <img src={G11} className="exp2i"></img>
</div> </Fade>
<br />

<Fade left>
<div className='exp1'>
    <h4 className='exp1h'> OUR GOALS</h4>
<p className='exp1p'>&emsp; &emsp;

<li><span className='exp1pd'> Enhanced Online Visibility and Credibility:</span> Increase the company's online presence to boost brand visibility and establish credibility in the electronic control panel manufacturing industry.</li><br />

<li><span className='exp1pd'>Lead Generation and Customer Engagement:</span> Implement effective strategies to generate leads and engage potential customers, fostering inquiries and consultations for the company's products and services.</li><br />

<li><span className='exp1pd'>Global Expansion and Reputation Building:</span> Leverage the website to expand the company's global reach, emphasizing its ISO 9001 certification and building a positive brand reputation through success stories with international clients.<br />
</li>
</p>
</div></Fade>



    </div>


        </div>
  )
}

export default Success