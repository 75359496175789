import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import A from "./../../Asset/p3.webp";
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
import "./P3.css"
const P3 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Nav />
          <br/> <br/> <br/> <br/> <br/>
        
          <div className="P3">

<div className='P1c1'>
<h3 className='p1h p1hy'>GENERATORPANEL :</h3>
<br />
<Fade left>
<p className='p1hp'>
&emsp;&emsp;A generator control panel is a display parameter that presents various details and parameters, such as current, voltage, and frequency. While some control panels have built-in displays so that operators could visually review a generator’s function, many other control panels have meters and gauges that will display information.

Whether the control panel has a built-in display, meters, or gauges, they are usually constructed in a metal weatherproof housing and mounted on the generator, along with vibration proof padding to help insulate the control panel from shocks..
</p>
<br />
<h5 className='p1h'>FEATURES</h5>
<br />
<p  className='p1l p1hp'>
<li> APPROVED QUALITY</li>
<li> SKILLED KNOWLEDGE APPLIED</li>
<li> VERIABLE SPECIFICATION APPLIED</li>

</p>

</Fade>


</div>

<Fade right>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>
</Fade>

</div>
<Whatsapp></Whatsapp>
<Cb />
    
        <Foot /></div>
  )
}

export default P3