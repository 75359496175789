import React from 'react'
import A from "./../../Asset/d3.png";
import B from "./../../Asset/d2.png";
import C from "./../../Asset/d1.png";

import "./Wwd.css";
import Fade from 'react-reveal/Fade';

const Wwd = () => {
  return (
    <div><br />
<h2 className='hed'>WHAT WE DO</h2>

      <div className='fl'>


      <Fade right>
<div className='card '>
    <div  className='c1'>
<img src={A} className="imgs"></img>
<h4 className='hd'> MANUFACTURING SERVICES </h4>

  <ul className='wu'>
<li>Control Panels</li>
<li>Power Panels</li>
<li>UPS,SPMs/customised Panels-PLC,VFD Panels etc</li>
<li>PV Solar and Solar Heating system for residential and industrial projects</li>
<li>Load Banks, Battery chargers</li>
<li>Assembly lines for automobile Manufacturing</li>
<li>Special Purpose machines</li>
</ul>

  </div>
    </div>
    </Fade>

    <div className='card'>
  <div  className='c1'>
<img src={B} className="imgs"></img>
<h4 className='hd'> CONSULTING SERVICES </h4>

<ul className='wu'>
<li>Turnkey Electrical and Engineering solutions.</li>
<li>Electrification of Greenfield projects and commercial buildings.</li>
<li>Design and Consultancy services for PV solar and BESS systems.</li>
<li>AMC services.</li>
<li>CAD Electrical and PV Solar Designing</li>
<li>Integration of advanced automation technologies</li>
</ul>
 
   </div>
    </div>

<Fade left>
<div className='card'>
<div  className='c1'>
<img src={C} className="imgs"></img>
<h4 className='hd'> TRADING SERCVICES </h4>

<ul className='wu'>
<li>Partners with Top brands</li>
<li>Complete Lighting solutions</li>
<li>PV Solar, Motors, MCB, MCCB, DB, Fans Geysers, pumps, meters, switches, automatic control switches</li>
<li>Cables& wires for enhanced efficiency in electrical systems.</li>
<li>Machine building systems</li>
<li>Building management systems</li>
<li>Earthing and lightening arrester</li>
</ul>

</div>

    </div></Fade>
    
      </div>
      
      </div>
  )
}

export default Wwd