import React from 'react'
import "./What.css"

import Fade from 'react-reveal/Fade';

import G1 from './../../Asset/g1.jpg';
import G2 from './../../Asset/g2.jpg';
import G3 from './../../Asset/g3.png';
import G4 from './../../Asset/g4.png';
import G5 from './../../Asset/g5.jpg';

import G7 from './../../Asset/g7.jpg';
import G8 from './../../Asset/g8.jpg';
import G9 from './../../Asset/g9.jpg';
import G10 from './../../Asset/g10.jpg';
import G11 from './../../Asset/g11.jpg';
import G12 from './../../Asset/g12.jpg';
import G13 from './../../Asset/g13.jpg';
import G14 from './../../Asset/g14.jpg';
import G19 from './../../Asset/g15.jpg';
import G15 from './../../Asset/g16.jpg';
import G16 from './../../Asset/g17.jpg';
import G17 from './../../Asset/g18.jpg';

import P1 from "./../../Asset/p1.webp";
import P2 from "./../../Asset/p2.webp";
import P3 from "./../../Asset/p3.webp";
import P4 from "./../../Asset/p4.webp";
import P5 from "./../../Asset/p5.webp";
import P6 from "./../../Asset/p6.webp";
import P7 from "./../../Asset/p7.webp";
import P8 from "./../../Asset/p8.webp";
import P9 from "./../../Asset/p9.webp";
import P10 from "./../../Asset/p10.webp";
import P11 from "./../../Asset/p11.webp";
import P12 from "./../../Asset/p12.webp";
import P13 from "./../../Asset/p13.webp";
import P14 from "./../../Asset/p14.webp";

const What = () => {

return(
<div>

<Fade left>
<div className='wl wl1'>
    
 
    <div className='dri'> 
    <h3 className='hed3'>MANUFACTURING INDUSTRY </h3> <br />
    <p className="whatp">&emsp;&emsp;Ambionics is a leading electronic control panels manufacturer in Pune, Maharashtra. With over three decades of experience and ISO 9001 certification, the company is recognized for its commitment to high-quality manufacturing in the fields of power panels, control panels, and generator panels. Ambionics' global presence and customer-centric approach solidify its position as a reputable force in the manufacturing industry</p>
    
    
    </div>

    <div className='dim' >
 <img src={P1} className='ile k1' />
    <img src={P11} className='ile k2' />
    <img src={P13} className='ile k3' />

    </div>

</div>
</Fade>

<Fade right>
<div className='wl wl2'>
    
<div className='dim' >
 <img src={P1} className='ile k1' />
    <img src={G1} className='ile k2' />
    <img src={G2} className='ile k3' />

    </div>
 
    <div className='dri'> 
    <h3 className='hed3'>TRADING INDUSTRY </h3>  <br />
    <p className="whatp">&emsp;&emsp;Ambionics, with over three decades of experience, extends its expertise to trading partnerships, offering a diverse range of electrical and engineering products. As a reputable trader, Ambionics focuses on delivering quality solutions, building on its ISO 9001 certification and fostering strong relationships with over 1000 satisfied customers worldwide. In the realm of trading, Ambionics stands out as a reliable source for power control panels and related solutions.</p>
    
    
    </div>



</div>
</Fade>


<Fade left>
<div className='wl wl1'>
    
 
    <div className='dri'> 
    <h3 className='hed3'>CONSULTANCY IN SOLAR </h3>  <br />
    <p className="whatp">&emsp;&emsp;We have our experts personliaze your solar system to match your solar goals either in person or over the phone/computer. We review your electricity bill, calculate how much will save with solar, and then design your new solar solution on the spot. This design includes a diagram of what your panels will look like on your roof. Once the design is set, our consultant will walk you through our different solar products that fit your financial needs.</p>
    
    
    </div>

    <div className='dim' >
 <img src={G3} className='ile k1' />
    <img src={G4} className='ile k2' />
    <img src={G5} className='ile k3' />

    </div>

</div>
</Fade>

<Fade right>
<div className='wl wl2'>
    
<div className='dim' >
 <img src={G8} className='ile k1' />
    <img src={G19} className='ile k2' />
    <img src={G7} className='ile k3' />

    </div>
 
    <div className='dri'> 
    <h3 className='hed3'>PRESS PART INDUSTRY </h3>  <br />
    <p className="whatp">&emsp;&emsp;In the realm of press part manufacturing, Ambionics stands out as a seasoned leader with over three decades of experience. Specializing in the production of precision components, they offer a diverse range of solutions for the press part industry, providing reliable and innovative products to meet the unique needs of clients in Pune, Maharashtra, and beyond. Ambionics' commitment to quality, coupled with their ISO 9001 certification, makes them a trusted partner for those seeking excellence in the press part manufacturing sector.</p>
    
    
    </div>



</div>
</Fade>

<Fade left>
<div className='wl wl1'>
    
 
    <div className='dri'> 
    <h3 className='hed3'>DESIGNING AND ANSYS </h3>  <br />
    <p className="whatp">&emsp;&emsp;
Designing and Ansys, as prominent leaders in the field, seamlessly integrate a comprehensive range of physics and engineering disciplines. Their cutting-edge solutions extend across diverse industries, offering unparalleled capabilities to understand and optimize real-world product performance. Leveraging advanced technology, they provide scalable and interoperable tools, empowering users with extensive insights and ensuring excellence in simulation and design.</p>
    
    
    </div>

    <div className='dim' >
 <img src={G9} className='ile k1' />
    <img src={G10} className='ile k2' />
    <img src={G11} className='ile k3' />

    </div>

</div>
</Fade>

<Fade right>
<div className='wl wl2'>
    
<div className='dim' >
 <img src={G13} className='ile k1' />
    <img src={G12} className='ile k2' />
    <img src={G14} className='ile k3' />

    </div>
 
    <div className='dri'> 
    <h3 className='hed3'>ELECTRICAL SIMULATION MANUFACTURERS </h3>  <br />
    <p className="whatp">&emsp;&emsp;Ambionics, a distinguished Electrical Simulation Manufacturer based in Pune, Maharashtra, stands out as a key player in the industry. Leveraging over three decades of expertise in electronics, electrical, and automation, Ambionics is renowned for delivering high-quality Electrical Simulation products. With ISO 9001 certification and a global presence, they offer innovative solutions, making them a reliable choice for diverse applications.</p>
    
    
    </div>



</div>
</Fade>
</div>


)



}

export default What