import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from './../../Asset/logo192.png';
export default function Whatspp() {
    return (
        <div className="App">
            <FloatingWhatsApp
                phoneNumber="9822431299"
                accountName="AMBIONICS"
                avatar={logo}
                chatMessage="Hello, We Are AMBONICS"
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}