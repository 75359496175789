import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./Foot.css"
import logo2 from './../../Asset/logo192.png';
import Heart from "./../../Asset/eziigj.gif"
import Fade from 'react-reveal/Fade';


const Foot = () => {


    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_5nlyw6e', 'template_bd7vtha', form.current, 'bsz7LIwTvX6ZYGIM3')
        .then((result) => {
  
          alert("Message Sent Successfully !!!")
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <div className='footer'>

<div className='footer1'>

     <div className='column in'>
    
    <div>
<img src={logo2} className="fi"/>
{/* <h4 className='fh4'>AMBIONICS</h4> */}
<p className='fp'>Ambionics was established in 1987 and is known as leading company who are electronic control panels manufacturer in Pune, Maharashtra.</p>
    </div>


</div>   

<br />
<div className='column cd'>
    
    <div>
<p className='fh5'>EMAIL :</p>
<a className='fp2' href="mailto:sales@ambionicsindia.com">sales@ambionicsindia.com</a>  
    </div>

    <div>
<p className='fh5'>PHONE :</p>
<a className='fp2' href="tel:9822431299">+91 9822431299
 </a>  
    </div>

    <div>
<p className='fh5'>ADDRESS :</p>
<a className='fp2'>SHED NO 3 S NO 134/1/2/1 DALVIWADI DHAYRI PUNE NEAR NANDED PHATA BEHIND BIOMARK LABORATORY PUNE 411041</a>  
    </div>




</div>
<br></br>


<div className='column ql'>
    <p className='fh5'>QUICK LINKS</p>
<div  className='hu'>
    <li><a href="/ " className='apal'>HOME</a></li>
    <li><a href="/About" className='apal'>ABOUT</a></li>
    <li><a href="/Activities" className='apal'>ACTIVITIES</a></li>
    <li><a href="/Products" className='apal'>PRODUCTS</a></li>
    <li><a href="/Download" className='apal'>CATALOGUE</a></li>
    <li><a href="/Clientele" className='apal'>CLIENTELE</a></li>
    <li><a href="/Gallry" className='apal'>GALLERY</a></li>
    <li><a href="/Contact" className='apal'>CONTACT</a></li>
</div>
</div>

<br />
<div className='column ap'> 

<p className='fh5'>ENQUIRE HERE</p>
{/*
<div  className='hu2'>
<li><a href="/PowerPanel" className='apal'>POWER PANEL</a></li>
<li><a href="/ControlPanel" className='apal'>CONTROL PANEL</a></li>
<li><a href="/GeneratorPanels" className='apal'>GENERATOR PANEL</a></li>
<li><a href="/AutomationPanels" className='apal'>AUTOMATION PANEL</a></li>
<li><a href="/LoadBank" className='apal'>LOAD BANK</a></li>
<li><a href="/PLCPanel" className='apal'>PLC BANK</a></li>
<li><a href="/OperatorPanels" className='apal'>OPERATOR PANEL </a></li>
<li><a href="/BatteryCharger" className='apal'>BATTERY CHARGER </a></li>
<li><a href="/UPS" className='apal'>UPS </a></li>
<li><a href="/SpecialPurposeMacines" className='apal'>SPECIAL PURPOSE MACHINE </a></li>
<li><a href="/HydroTestingMacine" className='apal'>HYDRO TESTING MACHINE</a></li>
<li><a href="/BushPressingMachine" className='apal'>BUSH PRESSING MACHINE</a></li>
<li><a href="/NutRunnerMachine" className='apal'>NUT RUNNER MACHINE</a></li>
<li><a href="/AssemblyFIxtures" className='apal'>ASSEMBLY FIXTURES</a></li>

</div>
*/}

<form  ref={form} onSubmit={sendEmail}>

<input placeholder='ENTER YOUR NAME' className='I1' name='name' id='name' type="text"></input><br />
<input placeholder='ENTER MOBILE NO' maxLength={10} className='I1' name='phn' id='phn' type="text"></input><br />
<input placeholder='ENTER YOUR EMAIL ID' className='I1' name='mail' id='mail' type="email"></input><br />
<textarea placeholder='DESCRIPTION' rows="4" className='t1' name='des' id='des'></textarea><br />
<input type="submit" value="SUBMIT" />
</form>


</div>
</div>

<div className='footer2'>
<center className='klk'>Copyright © 2023 AMBIONICS | Made with <img src={Heart} className="hrt"></img> by CORTICA WEB SOLUTIONS PVT LTD</center>
</div>

    </div>
  )
}

export default Foot