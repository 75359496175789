import React from 'react'
import I1 from './../../Asset/a1.webp'
import Fade from 'react-reveal/Fade';

import "./info.css"
const Info = () => {
  return (
    <div className='info'>
 <Fade right>   
<div className='a'>
<img src ={I1} className="img" />
</div></Fade>

<Fade left>   
<div className='b'>

<h4 className='h4'> Welcome To Ambionics </h4>
<p className='p'>
&emsp;&emsp;&emsp;Ambionics was founded in 1987 and has over thirty years of experience and knowledge in the electrical, automation, and electronics domains. 
The company is a full-service solution provider that specializes in engineering and electrical product manufacture, automotive assembly line manufacturing, and mechanical and electrical consulting.
One of the top Control Panel Manufacturers in Pune, Ambionics has over 1000 happy clients worldwide and an ISO 9001 accreditation.
Ambionics has received high praise from customers all throughout India for their high-quality goods, timely services, and satisfied consumers. 
</p>

</div>

</Fade>


    </div>
  )
}

export default Info