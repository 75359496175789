import React from 'react'
import { useEffect } from 'react';
import Nav from './../Nav/Nav';
import Foot from './../Footer/Foot';
import "./P1.css"
import A from "./../../Asset/p1.webp";
import Whatsapp from './../../Components/Whatsapp/Wpp';
import Cb from './../CB/Cb'
import Fade from 'react-reveal/Fade';
const P1 = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>

<Nav />
      <br/> <br/> <br/> <br/> <br/>
    
<div className="P1">


<div className='P1c1'>
<h3 className='p1h p1hy'>POWER PANEL :</h3>
<br />
<Fade right>
<p className='p1hp'>
&emsp;&emsp;Ambionics is a leading Power Panel Manufacturers in Pune. We have an in-house Fabrication shop where we are continuously making Power Control Panels, Remote Control Panels as per the specific requirements of our esteemed customers. We use the best quality of switchgear made by leading companies L&T, ABB, Schneider, etc. All special requirements of our clients are met while designing the control circuits. We have vast experience in manufacturing & supplying electrical products, we manufacture our products in accordance to the industry norms to ensure the highest degree of quality.
</p>

<br />
<h5 className='p1h'>Consider the following before investing in Power Panels</h5>

<p className='p1hp'>&emsp;&emsp;As one of the best Power Control Panel Manufacturers in Maharashtra. Apart from this, we have also used cutting edge technology during production to ensure notable features like durability, reliability & long operating life. Our engineering team has used high grade raw materials while developing Control Panel.</p>
<br />
<h5 className='p1h'>FEATURES</h5>

<p  className='p1l p1hp'>
<li> Long Operating Life</li>
<li> Advance Technology</li>
<li> Excellent Performance</li>
<li> Cost Effective</li>
</p>

<br />
<h5 className='p1h'>Still Have Doubts? Get In Touch Now</h5>

<p className='p1hp'>&emsp;Being a top Electric Power Panel Suppliers in India, we hear your diverse demands and deliver the end-model accordingly. Reach us to take the conversation ahead.</p>

</Fade>

</div>


<Fade left>
<div className='P1c2'>
    <div className='P1im'>

    <img src={A} className="p1br"></img>

    </div>
</div>

</Fade>
</div>

<Whatsapp></Whatsapp>
<Cb />
    <Foot />


    </div>
  )
}

export default P1